import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { Fragment } from "react"
import {
  CardTitle,
  ImageCardContainer,
  ImageContainer,
  ImageFadeIn,
  ImageScaleTransition,
  ImgDesc,
  ImgTitle,
} from "../commons/HighlightsCards.style"
import {
  DescriptionPara,
  VerticalLine,
  VerticalLineContainer,
} from "../commons/ImageDesc"
import {
  PosterContainer,
  PosterImageContainer,
  PosterTitle,
} from "../commons/PosterContainer.style"

import { HighLightsContainer } from "../home/homestyles"
import { BottomPaddingBlock } from "../performer/performerStyles"
import { PageDescriptionContainer } from "./practitionerstyles"

const Practitioner = () => (
  <Fragment>
    <PosterTitle color="#00000">PRACTITIONER</PosterTitle>
    <PageDescriptionContainer backgroundColor="#000000">
      <PosterImageContainer width="780px">
        <ImageFadeIn>
          <StaticImage
            src="../../images/Practitioner/practitioner.png"
            alt="practitioner"
            placeholder="dominantColor"

          />
          <CardTitle style={{ padding: '8px' }}>
            A dynamic embodied present, rooted in rich tradition with a vision
            for the future.
          </CardTitle>
        </ImageFadeIn>
      </PosterImageContainer>
    </PageDescriptionContainer>

    <PosterContainer backgroundColor="#000000">
      <DescriptionPara>
        A dance journey spanning six decades of collective experience, from
        early learnings in India with 2-hour performances of the solo repertoire
        of classical traditions, to Berlin-based work in cross-cultural set-ups
        as performer, teacher and choreographer, to academic research,
        Rajyashree Ramesh’s work with the traditional vocabulary reveals over
        the decades a rich tapestry of varied activity. The innumerable
        evening-filling solo and group performances, multinational stage
        productions, workshops for varied target groups, radio/TV/newspaper
        interviews, and lectures/lecture-performances worldwide always in step
        with time. Enhanced by her transcultural interactions, her dance and
        choreographic signature evolved to become multidimensional in approach,
        her teaching pioneering in methodology, her academic research bringing
        perspectives beyond the conventional fields Indian performing arts
        traditions are studied under.
      </DescriptionPara>
      <BottomPaddingBlock />
    </PosterContainer>
    <VerticalLineContainer>
      <VerticalLine />
    </VerticalLineContainer>
    <div style={{ marginTop: "150px" }}>
      <HighLightsContainer>
        <ImageCardContainer gap="40px">
          <Link to="/performer">
            <ImageContainer>
              <ImageScaleTransition>
                <StaticImage
                  src="../../images/Practitioner/leftcard.png"
                  alt="researcher"
                  placeholder="dominantColor"
                />
                <CardTitle>
                  <ImgTitle>performer -</ImgTitle>
                  <ImgTitle>choreographer</ImgTitle>
                  <ImgDesc>classical-innovative</ImgDesc>
                  <ImgDesc>multi-disciplinary.</ImgDesc>
                </CardTitle>
              </ImageScaleTransition>
            </ImageContainer>
          </Link>

          <Link to="/educator">
            <ImageContainer>
              <ImageScaleTransition>
                <StaticImage
                  src="../../images/Practitioner/educatorcard.png"
                  alt="educator"
                  placeholder="dominantColor"
                />
                <CardTitle>
                  <ImgTitle>educator</ImgTitle>
                  <ImgDesc>pioneering</ImgDesc>
                  <ImgDesc>cross-cultural</ImgDesc>
                </CardTitle>
              </ImageScaleTransition>
            </ImageContainer>
          </Link>
        </ImageCardContainer>
      </HighLightsContainer>
    </div>
  </Fragment>
)

export default Practitioner
