import * as React from "react"
import Layout from "../components/layout"
import Practitioner from "../components/practitioner"
import Seo from "../components/seo"

const logo = require("../images/logo.svg").default

const PractitionerPage = () => (
  <Layout
    backgroundColor="white"
    logo={logo}
    menuBGColor="black"
    hamburgerColor="black"
    footerColor="black"
  >
    <Seo title="Rajyashree Ramesh" />
    <Practitioner />
  </Layout>
)

export default PractitionerPage
