import styled from "@emotion/styled"

export const Background = styled.div`
  position: absolute;
  background-color: #000000;
  height: 200%;
  width: 100%;
  z-index: -20;
  top: 100vh;
`

export const ImageHeading = styled("h3")`
  position: absolute;
  font-family: Marcellus;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  color: white;
  font-style: normal;
  font-weight: normal;
  padding: 30px;
  font-size: 34px;
  line-height: 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
`

export const PracticeCaption = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: black;

  & > p {
    width: 1024px;
    font-family: Marcellus;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    margin-top: 2%;
    margin-bottom: 200px;
    padding: 4%;
  }
  & > ul {
    list-style-position: inside;
    width: 980px;
    font-family: Marcellus;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
  }
`

export const PageDescriptionContainer = styled.div(
  {
    paddingInline: "10%",
  },
  ({ backgroundColor }: { backgroundColor?: string }) => ({
    background: `linear-gradient(to bottom, white 0%, white 50%, ${backgroundColor} 50%, ${backgroundColor} 50%, ${backgroundColor} 50%)`,
  })
)
