import styled from "@emotion/styled"
import { ImageContainer, ImgDesc } from "../commons/HighlightsCards.style"

export const LandingContent = styled("div")`
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 50px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;

  width: 75%;

  @media (max-width: 600px) {
    display: none;
  }
`

export const HighLightsContainer = styled("div")`
  padding: 9%;
  background: #fff;
`
export const HighLightHeader = styled("div")`
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 50px;
  text-align: center;
  color: #000000;
  margin-bottom: 9%;
`

export const DanceText = styled(ImgDesc)`
  font-weight: 400;
  font-size: 34px;
  line-height: 50px;
  width: 90%;
  text-transform: initial;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 1024px) {
    width: 60%;
  }
`

export const DanceTextMobile = styled(ImgDesc)`
  background: black;
  padding: 50px 30px;
  font-size: 18px;
  line-height: 26px;

  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: block;
  }
`

export const HomeImageContainer = styled(ImageContainer)`
  padding: 50px 0;
  background: black;
`
